<template>
  <div>
    <input
      :fake-name="name"
      class="box"
      type="text"
      :placeholder="placeholder"
      autocomplete="off"
      @blur="blurInput"
      v-model="iValue"
      :ref="`input${id}`"
      @input="inputKey"
    />
    <input type="hidden" :name="name" :value="Go.onlyNumbers(iValue)" />
  </div>
</template>

<script>
import Input from "../inputs/Input.vue";
export default {
  components: { Input },
  props: ["type", "value", "name", "placeholder", "format", "attrs"],
  data: function() {
    return {
      id: this.$global.fastID(),
      iValue: this.value,
    };
  },
  methods: {
    blurInput: function({ target }) {
      if (!target) return;
      let { value } = target;
      let newValue = this.numberFormat({ value, format: this.format });
      target.value = newValue;
    },
    numberFormat: function({ value, format }) {
      let fomatted = { USD: "$ " + this.number_format(value, 2) };
      return fomatted[format] || value;
    },
    inputKey: function(e) {
      let { value } = e.target;
      let newValue = value.replace(/\$/g, "");
      newValue = newValue.replace(/\s/g, "");
      if (isNaN(newValue)) {
        this.iValue = this.numberFormat({ value: this.only_number(newValue), format: this.format });
      }
    },
    init: async function() {
      await this.sleep(10);
      const target = this.$refs[`input${this.id}`];
      if (!target) return this.init();
      target.value = this.numberFormat({ value: target.value, format: this.format });
    },
  },
  beforeMount: function() {
    this.init();
  },
};
</script>
