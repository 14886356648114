<template>
  <div class="block-input">
    <label>{{ label }}</label>
    <input
      :class="`block-input-input ${_class}`"
      :type="type"
      :name="name"
      :value="value"
      :placeholder="placeholder"
      required
      @blur="required && $validate.validateInput($event, { tooltip: false })"
      :accept="accept"
      @change="$emit('change', $event)"
    />
  </div>
</template>

<script>
export default {
  props: ["name", "type", "label", "placeholder", "value", "required", "accept", "_class"],
};
</script>

<style lang="scss" scoped>
.block-input {
  &-input {
    height: auto !important;
    padding: $mpadding/1.2 $mpadding !important;
  }
  &-input[type="file"] {
    cursor: pointer;
  }
}
</style>
